
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildDate": "2025-01-24T11:41:24.414Z",
  "AZURE_CLIENT_ID": "dc28c72c-cbf1-4e33-aede-ce5fe276af28",
  "AZURE_TENANT_ID": "fb0af7d1-8a83-48bc-8d2d-cd92034c79d4",
  "DEV_ENDPOINT": "https://api.dev.advisor.securitas.com",
  "SENTRY_DSN": "https://2c2737b93ea0cc3b4b4f4f86fda3ac77@o4508301541834752.ingest.de.sentry.io/4508330348642384",
  "SENTRY_ENVIRONMENT": "Dev",
  "APP_INSIGHTS_CONNECTION_STRING": "InstrumentationKey=b988ceb7-8378-4551-9a1e-b82182a40335;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=20b5dfce-e3a5-4479-a7e0-22b8ffa744da",
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
